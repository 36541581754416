/* Tab navigation */
.nav-tabs {
    background-color: #ffffff;
    border-radius: 1.5px;
  }
  
  .nav-tabs .nav-link {
    color: #333;
    border: none;
    border-radius: 1.5px;
    transition: background-color 0.3s ease;
  }
  
  .nav-tabs .nav-link.active {
    background-color: #007bff;
    color: #fff;
    border-bottom: 2px solid #ffffff;
  }

button-primary {
    font-size: 17px;
    padding: 0.5em 2em;
    border: transparent;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
    background: dodgerblue;
    color: white;
    border-radius: 0px;
   }
   
   button-primary:hover {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(30,144,255,1) 0%, rgba(0,212,255,1) 100%);
   }
   
   button-primary:active {
    transform: translate(0em, 0.2em);
   }

   .btn-primary {
    border-radius: 1.5px;
   }

   .btn-warning {
    border-radius: 1.5px;
   }
   .btn-success {
    border-radius: 1.5px;
   }

   select {
    border-radius: 1.5px !important;
   }

   input:not([name=number]) {
    border-radius: 1.5px !important;
   }

   .checkbox {
    border-radius: 1.5px !important;
   }

   .btn-outline-success, .btn-outline-danger {
    border-radius: 1.5px !important;
   }

   .modal-content {
    border-radius: 1.5px !important;
    border: 0px;
   }

   div.fade.in.modal {
    display:flex !important;
  }
  
  .modal-dialog {
    margin: auto;
  }
   /* custom-tabs.css */


  
  /* Tab content */
  /* .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
  } */


/* Custom styles for Nav.Item in tabs */

  
  