.tr_crs_version:hover {
    cursor: pointer;
    background-color: rgb(100, 101, 104);
    color: white;
    /* font-weight: bold; */
}

.closeBtn_course {
    padding: 0.5rem 2rem;
    border-radius: 50%;
    background-color: red;
    color: black;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.closeBtn_course:hover {
    background-color: black;
    color: red;
}