.org-container{
    margin: 2%;
}
.table-responsive {
    width: 350vh;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
}
/* .custom-map-modal{ */
    /* width:2000px; */
    /* margin-left: -90%; */
    /* text-align: center; */
 /* } */
 .custom-map-modal .modal-content {
    position: absolute;
    /* margin-left: -120vh; */
    display: flex;
    /* flex-direction: column; */
    left: 3%;
    width: 94%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-dialog{
    max-width: 100vw;
}

#addHomeInOrg{
    width: 60%;
}

/* .highlightUser{
    text-decoration: underline;
} */
/* .active {
    color: yellow;
    background: red;
  } */



  .scrollButtons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .diamondButton {
    position: absolute;
    width: 100px;
    height: 50px;
    transform: rotate(-45deg);
    margin: 20px;
  }